import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { FirestoreService } from '../services/firestore.service';
import { Observable, tap } from 'rxjs';
import { ThemeService } from '../services/theme.service';


export const accessCodeGuard: CanActivateFn = (route, state) => {
  const accessCode = route.paramMap.get('accessCode') || '';
  const fireStoreService = inject(FirestoreService);
  const themeService = inject(ThemeService);
  return isValidAccessCode(accessCode,fireStoreService,themeService)
};

function isValidAccessCode(code: string , fireStoreService:FirestoreService,themeService:ThemeService): Observable<boolean> | boolean {  
  return fireStoreService.accessGranted? fireStoreService.accessGranted:fireStoreService.incrementAccess(code).pipe(
    tap((accessGranted) => { 
      const theme = fireStoreService.publicData?.theme;
      themeService.setTheme(theme);
      console.log('App Guard Access Granted: ', accessGranted);
    })
  ); 
}
