import { Component } from '@angular/core';
import { FirestoreService } from 'src/app/services/firestore.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-card-header',
  templateUrl: './card-header.component.html',
  styleUrl: './card-header.component.scss'
})
export class CardHeaderComponent {
  public hostName: string = ''
  public iconSrc: string = this.getIcon()

  constructor(
    private fireStoreService: FirestoreService,
    public themeService: ThemeService,
  ) {
    this.hostName = String(
      this.fireStoreService.publicData?.hostName
    ).toUpperCase();
  }

  //select the icon depending on theme
  private getIcon():string {
    switch (this.themeService.myTheme()) {
      // case 'chic' :
      //   return './assets/arch.svg'
      // case 'joy' :
      //   return './assets/rings.svg'
      // case 'fun' :
      //   return './assets/wedding.svg'
      default:
        console.log('default')
        return './assets/rings_bold.svg'
    }
  }

}