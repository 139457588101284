import { Injectable, signal } from '@angular/core';
import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  // Define the signal with an initial value
  myTheme = signal<string>('');
  


  setTheme(theme:string=''){
    this.myTheme.set(theme)
  }
}
