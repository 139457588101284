import { Component, OnDestroy, OnInit, Renderer2, effect } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { LoadingService } from './services/loading.service';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit,OnDestroy {
  title = 'social-event-web';
  // Create a Subject that will emit a value when the component is destroyed
  private destroy$ = new Subject<void>();
  constructor(
    private tanslateService:TranslateService,
    public loadingService:LoadingService,
    public themeService: ThemeService,
    private renderer:Renderer2
  ){
    this.tanslateService.addLangs(['fr','en']);
    this.tanslateService.setDefaultLang('en');
    this.tanslateService.use('en');
    effect(() => {
      //apply background image according to picked theme
      this.renderer.setAttribute(document.body,"theme",this.themeService.myTheme())
    });
  }
  ngOnInit(): void {
    document.body.className = '';
  }

  ngOnDestroy(): void {
    // Emit a value to complete the observable and prevent memory leaks
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}