import { Injectable } from '@angular/core';
import { FullMetadata, ListResult, Storage, UploadResult, getBlob, getDownloadURL, getMetadata, list, ref, uploadBytes, uploadString } from '@angular/fire/storage';
import { NgxImageCompressService } from 'ngx-image-compress';
import { EMPTY, Observable, catchError, combineLatest, concatMap, finalize, forkJoin, from, map, mergeMap, of, switchMap, take, tap, toArray } from 'rxjs';
import { IFileInfo } from '../components/file-selector/file-selector.component';
import { LoadingService } from './loading.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private hostImage?:Blob;
  constructor(
    private imageCompress: NgxImageCompressService,
    private storage: Storage,
    private loadingService: LoadingService
  ) {}
  
  public addAudioBlobToFirestore(
    accessCode: string,
    guestName: string,
    blob: Blob,
  ): Observable<UploadResult> {
    const cleanedGuestName = this.removeIllegalCharacters(guestName);
    const storageRef = ref(
      this.storage,
      `${accessCode}/recordings/${cleanedGuestName}_${Date.now()}.mp3`
    );
    const uploadTask = uploadBytes(storageRef, blob, {
      customMetadata: {
        accessCode: accessCode,
        guestName:guestName
      },
    })
    return of({}).pipe(
      tap(() => this.loadingService.show()), // Show loading here
      switchMap(()=>this.getBlobHostPhoto(accessCode)), // Load host photo
      switchMap(() => from(uploadTask)), // Upload recording
      finalize(() => this.loadingService.hide()) // Hide loading on completion or error
    )
  }

  public addImageToFirestore(
    accessCode: string,
    guestName: string,
    fileInfoArr: IFileInfo[]
  ): Observable<UploadResult[]> {
    const cleanedGuestName = this.removeIllegalCharacters(guestName);
    this.loadingService.updateText('Uploading ...');
    
    const uploadTasks$ = from(fileInfoArr).pipe(
      concatMap((fileInfo, index) => {
        const name = `${this.getInvertedTimestamp()}_${fileInfo.file.name}`;
        const originalStorageRef = ref(
          this.storage,
          `${accessCode}/gallery/images/${name}`
        );
        const compressedStorageRef = ref(
          this.storage,
          `${accessCode}/gallery/images-compressed/${name}`
        );
  
        // Compress and upload in parallel
        const compressedImage$ = from(
          this.imageCompress.compressFile(
            fileInfo.blobUrl, 
            -1,  // orientation (auto)
            10,  // quality
            25, // ratio
          )
        ).pipe(
          take(1),
          switchMap((compressedBase64: string) => {
            // Extract MIME type and Base64 content
            const match = compressedBase64.match(/^data:(.+);base64,(.*)$/);
            if (!match) {
              throw new Error('Invalid Base64 string - cannot determine filetype.');
            }
        
            const contentType = match[1]; // e.g. "image/png" or "image/jpeg"
            const base64Data = match[2];  // the actual Base64-encoded content
        
            // Convert Base64 to raw bytes
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
      
            console.log('Compressed file size (bytes):', byteArray.byteLength);
        
            // Upload as bytes with the correct contentType + any custom metadata
            return from(
              uploadBytes(compressedStorageRef, byteArray, {
                contentType,
                customMetadata: {
                  accessCode,
                  guestName: cleanedGuestName
                }
              })
            );
          })
        );
  
        const originalUpload$ = from(
          uploadBytes(originalStorageRef, fileInfo.file, {
            customMetadata: { accessCode, guestName: cleanedGuestName },
          })
        ).pipe(take(1));
  
        // Run both uploads in parallel for each file
        return combineLatest([originalUpload$, compressedImage$]).pipe(
          mergeMap(([originalResult, compressedResult]) => [originalResult, compressedResult]), // Flatten the array
          tap(() => {
            const uploadedCount = index + 1;
            this.loadingService.updateText(`Uploaded ${uploadedCount} of ${fileInfoArr.length}...`);
          }),
          catchError((error) => {
            console.error(`Upload failed for file: ${fileInfo.file.name}`, error);
            return EMPTY; // Continue with the next file
          })
        );
      }),
      toArray() // Collect all results into a single array
    );
  
    return of({}).pipe(
      tap(() => this.loadingService.show()), // Show loading spinner
      switchMap(() => uploadTasks$), // Trigger the uploads
      finalize(() => {
        this.loadingService.updateText(); // Reset the loading text to default
        this.loadingService.hide(); // Hide loading spinner
      })
    );
  }
  
  public getBlobHostPhoto(accessCode: string): Observable<Blob | null>{
    if(this.hostImage)
      return of(this.hostImage);
    const storageRef = ref(this.storage, `${accessCode}/host-image/host_img`);
    return from(getBlob(storageRef)).pipe(
      tap((blob) => { 
        if(blob) this.hostImage = blob; 
      }),
      catchError(err => {
        // Handle the error or log it, and return a default value
        console.error('Error fetching host image:', err);
        return of(null); // Return null or a default Blob as a fallback
      })
    );
  }

  public getPaginatedDownloadUrlsAndMetadata(compressedDirectory: string,uncompressedDirectory:string, maxResults: number, pageToken: string|null): Observable<{ files: { url: string, metadata: FullMetadata }[], nextPageToken: string|null, totalCount:number }> {
    const storageRef = ref(this.storage, compressedDirectory);
    const listOptions = { maxResults, pageToken };

    return from(list(storageRef, listOptions)).pipe(
      mergeMap((res: ListResult) => {
        const fileObservables = res.items.map((item) =>{
          return forkJoin({
            url: of(this.generateTokenlessURL(`${uncompressedDirectory}/${item.name}`)),
            metadata: from(getMetadata(item)),
          }).pipe(catchError((error) => {
            return of(null); // Continue with the next file
          }))
        }
        );
        return fileObservables.length
          ? forkJoin(fileObservables).pipe(
              map((files) => ({
              
                files: files.filter(Boolean) as [], 
                nextPageToken: res.nextPageToken || null,
                totalCount: res.items.length,
              }))
            )
          : of({ files: [], nextPageToken: null, totalCount: 0 });
      })
    );
  }

  public getBlobAsObs(directory: string){
    const storageRef = ref(this.storage, directory);
    return from(getBlob(storageRef))
  }

  public async getBlobAsPromise(directory: string){
    const storageRef = ref(this.storage, directory);
    return getBlob(storageRef)
  }

  public wrapObservableLoading<T>(obs:Observable<T>):Observable<T>{
    return of({}).pipe(
      tap(() => this.loadingService.show()), // Show loading here
      switchMap(()=> obs), // Load host photo,
      finalize(() => this.loadingService.hide()) // Hide loading on completion or error
    )
  }

  private removeIllegalCharacters(filename: string): string {
    // Define illegal characters and remove them
    const illegalCharacters = /[\/:*?"<>|\\]/g; // Adding backslash for Windows
    return filename.replace(illegalCharacters, '');
  }
  private getInvertedTimestamp() {
    const maxTimestamp = 99999999999999;
    const currentTimestamp = parseInt(new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 14));
    return maxTimestamp - currentTimestamp;
  }
  private generateTokenlessURL(filePath:string):string {
    // URL-encode the file path so that slashes and special characters are properly represented
    const encodedFilePath = encodeURIComponent(filePath);
    
    // Construct and return the non-tokenized Firebase Storage URL
    return `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.projectId}.appspot.com/o/${encodedFilePath}?alt=media`;
  }
  
}
