import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollDirective } from "ngx-infinite-scroll";
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { FileSelectorComponent } from './components/file-selector/file-selector.component';
import { ImageGalleryComponent } from './components/gallery/gallery.component';
import { ImageDropComponent } from './components/image-drop/image-drop.component';
import { ModalComponent } from './components/modal/modal.component';
import { FileDropDirective } from './directives/file-drop.directive';
import { SafePipe } from './pipes/safe-pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader}from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http';

export function httpLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http,'./assets/i18n/','.json?v='+Date.now())
}

@NgModule({
  declarations: [
    AppComponent,
    CardHeaderComponent,
    SafePipe,
    ModalComponent,
    FileSelectorComponent,
    FileDropDirective,
    ImageDropComponent,
    ImageGalleryComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin' }),
    InfiniteScrollDirective,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [  
    provideFirebaseApp(() => initializeApp(environment.firebase)) as any,
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage())],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }